import {
  MessageSquare,
  MoreHorizontal,
  Paperclip,
  Text,
  Trash2,
} from 'lucide-react'
import { useRef, useState } from 'react'
import {
  FeatureSuggestion_,
  useFeatureSuggestionsApi,
} from 'src/api/hooks/apiFeatureSuggestions'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Dialog,
  DialogContent,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/shared/components/ui'
import { DialogHeader } from 'src/shared/components/ui/dialog'
import { Image } from 'src/shared/components/ui/image'
import { Separator } from 'src/shared/components/ui/separator'

import { ImagesCarousel } from './ImagesCarousel'
import { SuggestionSheet } from './SuggestionSheet'
import { VotingButton } from './VotingButton'

export const Suggestion = ({
  featureSuggestion,
  boardId,
  profile_id,
  isBoardOwner,
  textColor,
}: {
  featureSuggestion: FeatureSuggestion_
  boardId
  profile_id
  isBoardOwner
  textColor
}) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false)
  const { deleteFeatureSuggestion } = useFeatureSuggestionsApi(boardId)

  const suggestionRef = useRef<HTMLDivElement>(null)
  const votingButtonRef = useRef<HTMLDivElement>(null)
  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const imageCarouselRef = useRef<HTMLDivElement>(null)

  const images = featureSuggestion.image_urls
    ?.split(',')
    .filter((image) => image)

  const handleDeleteSuggestion = (suggestionId: number) => {
    if (isBoardOwner) {
      deleteFeatureSuggestion.mutate(suggestionId)
    }
  }

  const handleSuggestionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      votingButtonRef.current?.contains(event.target as Node) ||
      dropdownMenuRef.current?.contains(event.target as Node) ||
      imageCarouselRef.current?.contains(event.target as Node)
    ) {
      return
    }

    if (
      event.target === suggestionRef.current ||
      suggestionRef.current?.contains(event.target as Node)
    ) {
      setIsSuggestionModalOpen(true)
    }
  }

  return (
    <>
      <Column
        className="p-4 rounded-xl glass-noshadow"
        id="suggestion"
        ref={suggestionRef}
        onClick={handleSuggestionClick}
      >
        <Column className="mb-1">
          <Row className="justify-between items-center mb-1">
            <h3 className={`font-medium ml-1 text-sm ${textColor}`}>
              {featureSuggestion.title}
            </h3>

            {isBoardOwner && (
              <Row className="gap-2" ref={dropdownMenuRef}>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <MoreHorizontal
                      className={`w-4 h-4 cursor-pointer ${textColor}`}
                    />
                  </DropdownMenuTrigger>

                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onClick={() =>
                        handleDeleteSuggestion(featureSuggestion.id)
                      }
                    >
                      <Trash2 className={`w-4 h-4 mr-2`} />
                      Delete suggestion
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </Row>
            )}
          </Row>
        </Column>

        {images?.length && (
          <div ref={imageCarouselRef} className="cursor-zoom-in">
            <Image
              className="mx-auto rounded-md opacity-80"
              alt="Image illustration"
              src={images[0]}
              onClick={() => setIsCarouselOpen(true)}
            />
          </div>
        )}

        <Row className="gap-2 mt-2 items-center justify-between w-full">
          {featureSuggestion.description && (
            <Text className={`w-4 h-4 ${textColor}`} />
          )}
          {featureSuggestion.feature_suggestion_chat_messages?.length > 0 && (
            <Row c className=" gap-1">
              <MessageSquare className={`w-4 h-4 mr-2 ${textColor}`} />

              <span className={`text-sm ${textColor}`}>
                {featureSuggestion.feature_suggestion_chat_messages?.length}
              </span>
            </Row>
          )}
          {featureSuggestion.image_urls && (
            <Row c className=" gap-1">
              <Paperclip className={`w-4 h-4 mr-2 ${textColor}`} />

              <span className={`text-sm ${textColor}`}>
                {featureSuggestion.image_urls.split(',').length}
              </span>
            </Row>
          )}

          <div ref={votingButtonRef} className="ml-auto">
            <VotingButton
              textColor={textColor}
              featureSuggestion={featureSuggestion}
              profile_id={profile_id}
              score={featureSuggestion.feature_suggestions_votes?.reduce(
                (sum, vote) => sum + (vote?.score || 0),
                0
              )}
            />
          </div>
        </Row>
      </Column>

      {isBoardOwner && (
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent className="max-w-lg">
            <DialogHeader>Delete suggestion</DialogHeader>
            <Separator />

            <p>Are you sure you want to delete this suggestion?</p>
            <Button
              className="w-fit mt-4"
              variant="destructive"
              onClick={() => {
                deleteFeatureSuggestion.mutate(featureSuggestion.id)
                setIsModalOpen(false)
              }}
            >
              Delete
            </Button>
          </DialogContent>
        </Dialog>
      )}

      <SuggestionSheet
        featureSuggestionId={featureSuggestion.id}
        isModalOpen={isSuggestionModalOpen}
        setIsModalOpen={setIsSuggestionModalOpen}
        isBoardOwner={isBoardOwner}
      />

      <ImagesCarousel
        images={images}
        isCarouselOpen={isCarouselOpen}
        setIsCarouselOpen={setIsCarouselOpen}
      />
    </>
  )
}
