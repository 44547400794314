import { ArrowRight } from 'lucide-react'
import { KeyboardEvent, useState } from 'react'
import {
  FeatureSuggestionChatMessage,
  useFeatureSuggestionChatMessagesApi,
} from 'src/api/hooks/apiFeatureSuggestionsComment'
import { Column } from 'src/shared/components/Semantic/all'
import { Input } from 'src/shared/components/ui/input'
import { ScrollArea } from 'src/shared/components/ui/scroll-area'

import { Msg } from './Msg'

export const ChatSection = ({ featureSuggestion, isMyBoard, profile_id }) => {
  const { addFeatureSuggestionComment } = useFeatureSuggestionChatMessagesApi()
  const [message, setMessage] = useState('')

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onClick()
  }

  const onClick = () => {
    addFeatureSuggestionComment.mutate({
      message,
      type: isMyBoard ? 'admin' : 'user',
      profile_id,
      feature_suggestion_id: featureSuggestion.id,
    })
    setMessage('')
  }

  return (
    <Column>
      {featureSuggestion?.feature_suggestion_chat_messages?.length > 0 && (
        <ScrollArea id="scroll" className={`h-[170px] gap-2`}>
          {featureSuggestion?.feature_suggestion_chat_messages?.map(
            (message: FeatureSuggestionChatMessage, i) => {
              return (
                <Msg
                  key={i}
                  message={message}
                  canEdit={message.profile_id === profile_id}
                />
              )
            }
          )}
        </ScrollArea>
      )}

      <Input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Add a comment.."
        endContent={
          <ArrowRight
            className="w-4 h-4 mr-2 cursor-pointer"
            onClick={onClick}
          />
        }
      />
    </Column>
  )
}
