import {
  CodeSquareIcon,
  Link,
  Menu,
  Settings,
  UserRound,
  UsersRound,
} from 'lucide-react'
import { useState } from 'react'
import { Board } from 'src/api/hooks/apiBoards'
import { lightenColor } from 'src/pages/Boards/components/BoardCard'
import { paths } from 'src/routes/paths'
import { Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Sheet,
  SheetContent,
  SheetTrigger,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
} from 'src/shared/components/ui'
import { useAuth } from 'src/shared/hooks/authProvider'
import { useToast } from 'src/shared/hooks/use-toast'

import { EmbedTab } from './EmbedTab'
import { LeadsTab } from './LeadsTab'
import { SettingsSheet } from './SettingsSheet'

export const Header = ({
  textColor,
  board,
  isBoardOwner,
}: {
  textColor: string
  board: Board
  isBoardOwner: boolean
}) => {
  const lightenedColor = lightenColor(board?.color || '#000000', 50)
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const toast = useToast()
  const { session } = useAuth()

  return (
    <Row className="justify-between">
      <Row c className="gap-2">
        <h3 className={`text-lg font-normal ${textColor}`}>{board?.name}</h3>

        <div
          className="w-5 h-5 rounded-full"
          style={{
            background: `linear-gradient(to right, ${
              board?.color || '#000000'
            }, ${lightenedColor})`,
          }}
        />
      </Row>

      <Row c className="gap-1">
        <Row c className="gap-1">
          <UserRound className={`w-4 h-4 ${textColor}`} />
          <p className={`text-sm font-normal ${textColor}`}>
            {session?.user?.email || 'Anonymous'}
          </p>
        </Row>

        <Tooltip content="Share link to community board">
          <Button
            className="px-2 py-1 relative overflow-hidden"
            variant="ghost"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_FE_SERVER_URL}${paths.publicBoard}/${board?.id}`
              )

              toast.toast({
                title: 'Link copied to clipboard!',
                variant: 'success',
                duration: 2000,
              })
            }}
          >
            <Link className={`w-4 h-4 relative z-10 ${textColor}`} />
          </Button>
        </Tooltip>

        <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
          {isBoardOwner && (
            <SheetTrigger asChild>
              <Button className="px-2 py-1" variant="ghost">
                <Menu className={`w-4 h-4 ${textColor}`} />
              </Button>
            </SheetTrigger>
          )}

          <SheetContent showOverlay={false}>
            <Tabs defaultValue="settings" className="mt-7">
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="settings">
                  <Row c className="">
                    <Settings className="h-4 w-4 mr-2" /> Settings
                  </Row>
                </TabsTrigger>
                <TabsTrigger value="embed">
                  <Row c className="">
                    <CodeSquareIcon className="h-4 w-4 mr-2" /> Embed
                  </Row>
                </TabsTrigger>
                <TabsTrigger value="leads">
                  <Row c className="">
                    <UsersRound className="h-4 w-4 mr-2" /> Leads
                  </Row>
                </TabsTrigger>
              </TabsList>
              <TabsContent value="settings">
                <SettingsSheet boardId={board?.id} />
              </TabsContent>
              <TabsContent value="embed">
                <EmbedTab boardId={board?.id} />
              </TabsContent>
              <TabsContent value="leads">
                <LeadsTab boardId={board?.id} />
              </TabsContent>
            </Tabs>
          </SheetContent>
        </Sheet>
      </Row>
    </Row>
  )
}
