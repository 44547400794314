import React from 'react'

import { cn } from './utils'

interface CodeProps extends React.HTMLAttributes<HTMLPreElement> {
  children: React.ReactNode
}

export function Code({ children, className, ...props }: CodeProps) {
  return (
    <pre
      className={cn(
        'bg-gray-200 px-[0.3rem] py-[0.2rem] font-mono text-sm rounded inline-block overflow-x-auto',
        className
      )}
      {...props}
    >
      <code>{children}</code>
    </pre>
  )
}
