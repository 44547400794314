import { FormProvider, UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/shared/components/ui/form'
import { Input } from 'src/shared/components/ui/input'

import { Checkbox } from './ui'
import { ColorPicker } from './ui/color-picker'
import { Textarea } from './ui/textarea'

export const FormInput = ({
  name,
  label,
  description,
  methods,
  placeholder,
  endContent,
  disabled,
  className,
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
  placeholder?: string
  endContent?: React.ReactNode
  disabled?: boolean
  className?: {
    label?: string
    description?: string
  }
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className={className?.label || ''}>{label}</FormLabel>
          <FormControl>
            <Input
              {...field}
              placeholder={placeholder}
              endContent={endContent}
              disabled={disabled}
            />
          </FormControl>

          {description && (
            <FormDescription className={className?.description || ''}>
              {description}
            </FormDescription>
          )}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FormTextarea = ({
  name,
  label,
  description,
  methods,
  placeholder,
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
  placeholder?: string
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea {...field} placeholder={placeholder} />
          </FormControl>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FormCheckbox = ({
  name,
  label,
  description,
  methods,
  disabled,
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
  disabled?: boolean
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={() => (
        <FormItem>
          <FormControl>
            <Checkbox
              checked={methods.watch(name)}
              setChecked={(checked) => methods.setValue(name, checked)}
              disabled={disabled}
              value={name}
              label={label}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FormColorPicker = ({
  name,
  label,
  description,
  methods,
}: {
  name: string
  label: string
  description?: string
  methods: UseFormReturn<any>
}) => {
  return (
    <FormField
      control={methods.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <ColorPicker
              color={field.value}
              onChange={(color) => field.onChange(color)}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FormWrapper = ({
  onSubmit,
  methods,
  children,
}: {
  onSubmit: (values: any) => void
  methods: UseFormReturn<any>
  children: React.ReactNode
}) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-3">
        {children}
      </form>
    </FormProvider>
  )
}
