import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

export type InsertFeatureSuggestion =
  Database['public']['Tables']['feature_suggestions']['Insert']
export type UpdateFeatureSuggestion =
  Database['public']['Tables']['feature_suggestions']['Update']
export type FeatureSuggestion_ =
  Database['public']['Tables']['feature_suggestions']['Row'] & {
    feature_suggestions_votes: Database['public']['Tables']['feature_suggestions_votes']['Row'][]
    feature_suggestion_chat_messages: Database['public']['Tables']['feature_suggestion_chat_messages']['Row'][]
  }

export const useFeatureSuggestionsApi = (board_id: string) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const { data: feature_suggestions } = useQuery({
    queryKey: ['feature_suggestions', board_id],
    queryFn: async () => {
      const query = supabase
        .from('feature_suggestions')
        .select(
          `*, feature_suggestions_votes(*), feature_suggestion_chat_messages(*, profiles(email))`
        )
        .eq('board_id', board_id)
        .order('created_at', { ascending: false })

      return await query
    },
    enabled: !!board_id,
  })

  const addFeatureSuggestion = useMutation({
    mutationFn: async ({
      title,
      description,
      profile_id,
      image_urls,
      board_id,
      board_column_id,
      order,
    }: InsertFeatureSuggestion) =>
      await supabase.from('feature_suggestions').insert([
        {
          title,
          description,
          profile_id,
          board_id,
          image_urls,
          board_column_id,
          order,
        },
      ]),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['feature_suggestions'],
      })
    },
  })

  const deleteFeatureSuggestion = useMutation({
    mutationFn: async (id: number) =>
      await supabase.from('feature_suggestions').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['feature_suggestions', board_id],
      })
    },
  })

  const updateFeatureSuggestion = useMutation({
    mutationFn: async ({
      id,
      board_column_id,
      title,
      description,
      image_urls,
      order,
    }: UpdateFeatureSuggestion) =>
      await supabase
        .from('feature_suggestions')
        .update({ id, board_column_id, title, description, image_urls, order })
        .eq('id', id!),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['feature_suggestions', board_id],
      })
    },
  })

  return {
    feature_suggestions,
    addFeatureSuggestion,
    deleteFeatureSuggestion,
    updateFeatureSuggestion,
  }
}
