import { useQuery } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'

export const useOrgApi = (id) => {
  const supabase = useSupabaseClient()

  const { data: organizations } = useQuery({
    queryKey: ['organizations'],
    queryFn: async () =>
      await supabase
        .from('organizations')
        .select(
          `*, 
          widgets(*)`
        )
        .filter('id', 'eq', id),
    enabled: !!id,
  })

  return {
    organizations,
  }
}
