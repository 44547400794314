import { Link } from 'react-router-dom'
import { paths } from 'src/routes/paths'

import logoImage from './logo.png'

export const LogoIcon = () => {
  return (
    <Link
      to={paths.boards}
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20"
    >
      <img src={logoImage} alt="Logo" className="h-8 w-auto rounded-full" />
    </Link>
  )
}
