import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Bold, Italic, List, ListOrdered, Underline } from 'lucide-react'
import React, { useEffect } from 'react'

import { Button } from './ui/button'
import { cn } from './ui/utils'

interface RichTextProps {
  value: string
  onChange: (value: string) => void
  className?: string
  placeholder?: string
}

const RichText: React.FC<RichTextProps> = ({
  value,
  onChange,
  className,
  placeholder = 'Start typing...',
}) => {
  const extensions = [StarterKit, Placeholder.configure({ placeholder })]

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML())
    },
  })

  useEffect(() => {
    if (value) {
      editor?.commands.setContent(value)
    }
  }, [value])

  if (!editor) {
    return null
  }

  return (
    <div
      className={cn(
        className,
        'bg-white border border-gray-300 rounded-md overflow-hidden'
      )}
    >
      <div className="flex items-center space-x-2 p-2 border-b border-gray-200">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'bg-gray-200' : ''}
        >
          <Bold size={16} />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'bg-gray-200' : ''}
        >
          <Italic size={16} />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'bg-gray-200' : ''}
        >
          <Underline size={16} />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'bg-gray-200' : ''}
        >
          <List size={16} />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'bg-gray-200' : ''}
        >
          <ListOrdered size={16} />
        </Button>
      </div>

      <EditorContent editor={editor} className="p-4 text-sm" />
      <style>{`
        .tiptap.ProseMirror {
          min-width: 100px;
          min-height: 100px;
          border: none;
        }
        ProseMirror-focused {
          box-shadow: none;
        }
      `}</style>
    </div>
  )
}

export default RichText
