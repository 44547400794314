import { isEmail, useForm } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { Mail, X } from 'lucide-react'
import { useState } from 'react'
// import { useParams } from 'react-router-dom'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Card, Image } from 'src/shared/components/ui'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import { useToast } from 'src/shared/hooks/use-toast'

export const LoginSignup = () => {
  const [loading, setLoading] = useState(false)
  const supabaseClient = useSupabaseClient()
  const [success, setSuccess] = useState(false)
  const { toast } = useToast()
  // const { org_id } = useParams()

  const form = useForm({
    initialValues: {
      notSoSecretKey: '',
      email: '',
    },
    validate: {
      email: isEmail('Invalid email'),
    },
  })

  const handleLogin = async () => {
    form.validate()
    if (!form.isValid()) return
    setLoading(true)

    const { error } = await supabaseClient.auth.signInWithOtp({
      email: form.values.email.toLowerCase(),
      options: {
        emailRedirectTo: process.env.REACT_APP_FE_SERVER_URL,
        data: {
          role: 'admin',
        },
      },
    })

    if (error) {
      const msg = JSON.stringify(error.message).slice(0, 250)
      const message = msg.includes('Signups not allowed for otp')
        ? 'You need to create a user'
        : msg

      toast({
        variant: 'destructive',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <X className="h-4 w-4" />
            {message || 'Something went wrong'}
          </Row>
        ),
      })
    } else {
      setSuccess(true)
    }
    setLoading(false)
  }

  return (
    <div className="w-full lg:grid lg:grid-cols-2 h-[100vh]">
      <div className="flex items-center justify-center py-12">
        <Card className="mx-auto grid gap-6 w-[350px] p-6">
          <Column className="gap-2">
            <h1 className="text-xl font-normal">Login / Signup</h1>

            <p className="text-balance text-sm text-muted-foreground">
              Enter your email below 🚀
            </p>
          </Column>

          {success ? (
            <Card className="p-4 bg-green-50">
              <Column>
                <p className="text-balance text-sm">Success! ✅</p>
                <p className="text-balance text-sm">
                  To login, click the link in your email.
                </p>
              </Column>
            </Card>
          ) : (
            <Column className="gap-4">
              <Input
                label="Email"
                autoComplete="email"
                style={{ flex: 1 }}
                {...form.getInputProps('email')}
                placeholder="Your email"
                onKeyDown={getHotkeyHandler([['Enter', handleLogin]])}
              />

              <Button
                isLoading={loading}
                onClick={handleLogin}
                disabled={!form.values.email}
              >
                Send me a login link
                <Mail className="h-4 w-4 ml-2" />
              </Button>
            </Column>
          )}
        </Card>
      </div>

      <div className="hidden bg-muted lg:block">
        <Image
          src="https://i.pinimg.com/736x/c0/2c/d0/c02cd07c52fb8660e096f8a1bac9f417.jpg"
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  )
}
