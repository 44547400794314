import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

export type InsertFeatureSuggestionsVote =
  Database['public']['Tables']['feature_suggestions_votes']['Insert']

export const useFeatureSuggestionsVotesApi = () => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const addFeatureSuggestionVote = useMutation({
    mutationFn: async ({ feature_suggestions_id, score, profile_id }: any) =>
      await supabase.from('feature_suggestions_votes').insert({
        feature_suggestions_id,
        score,
        profile_id,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['feature_suggestions'] })
    },
  })

  return {
    addFeatureSuggestionVote,
  }
}
