import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button } from 'src/shared/components/ui/button'
import { useAuth } from 'src/shared/hooks/authProvider'

import { useProfilesApi } from '../../api/hooks/apiProfiles'

export const OnboardingPage = () => {
  const { session } = useAuth()
  const navigate = useNavigate()
  const { updateProfile, profiles } = useProfilesApi()

  useEffect(() => {
    if (profiles?.data && profiles?.data[0]?.completed_onboarding) {
      navigate(paths.settingsPage)
    }
  }, [profiles?.data])

  return (
    <Row c className="h-full">
      <Column className="p-4 rounded-xl gap-3">
        <p>Will go an onboarding tutorial here.</p>

        <Button
          className="w-fit"
          isLoading={updateProfile.isLoading}
          onClick={() => {
            updateProfile.mutate({
              completed_onboarding: true,
              id: session?.user.id!,
            })
          }}
        >
          Done
        </Button>
      </Column>
    </Row>
  )
}
