import { isEmail, useForm } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { ArrowRight, X } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useSupabaseClient } from 'src/api/supabase'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Card,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
} from 'src/shared/components/ui'
import { Button } from 'src/shared/components/ui/button'
import { Input } from 'src/shared/components/ui/input'
import { useToast } from 'src/shared/hooks/use-toast'

export const LoginOverlay = () => {
  const [loading, setLoading] = useState(false)
  const supabaseClient = useSupabaseClient()
  const { toast } = useToast()
  const { board_id } = useParams()
  const { board } = useBoardsApi({ boardId: board_id })

  const form = useForm({
    initialValues: {
      notSoSecretKey: '',
      email: '',
      password: '',
    },
    validate: {
      email: isEmail('Invalid email'),
    },
  })

  const handleSignup = async () => {
    form.validate()
    if (!form.isValid()) return
    setLoading(true)

    const { error, data } = await supabaseClient.auth.signUp({
      email: form.values.email,
      password: form.values.password,
      options: {
        data: {
          role: 'voter',
          organization_id: board?.data?.org_id,
        },
      },
    })
    console.log('🚀  data:', data)

    if (error) {
      const msg = JSON.stringify(error.message).slice(0, 250)
      const message = msg.includes('Signups not allowed for otp')
        ? 'You need to create a user'
        : msg

      toast({
        variant: 'destructive',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <X className="h-4 w-4" />
            {message || 'Something went wrong'}
          </Row>
        ),
      })
    }
    setLoading(false)
  }

  const handleLogin = async () => {
    form.validate()
    if (!form.isValid()) return
    setLoading(true)

    const { error } = await supabaseClient.auth.signInWithPassword({
      email: form.values.email,
      password: form.values.password,
    })

    if (error) {
      toast({
        variant: 'destructive',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <X className="h-4 w-4" />
            {error.message || 'Login failed'}
          </Row>
        ),
      })
    }
    setLoading(false)
  }

  const Signup = () => (
    <>
      <Column className="gap-2">
        <Input
          label="Email"
          autoComplete="email"
          style={{ flex: 1 }}
          {...form.getInputProps('email')}
          placeholder="Your email"
          onKeyDown={getHotkeyHandler([['Enter', handleSignup]])}
        />

        <Input
          label="Password"
          type="password"
          autoComplete="password"
          style={{ flex: 1 }}
          {...form.getInputProps('password')}
          placeholder="Your password"
          description={
            form.values.password.length < 5
              ? 'Password must be at least 6 characters'
              : undefined
          }
        />

        <Button
          className="mt-3"
          isLoading={loading}
          onClick={handleSignup}
          disabled={form.values.password.length < 5}
        >
          Save and enter
          <ArrowRight className="h-4 w-4 ml-1" />
        </Button>
      </Column>
    </>
  )

  const Login = () => (
    <>
      <Column className="gap-2">
        <Input
          label="Email"
          autoComplete="email"
          style={{ flex: 1 }}
          {...form.getInputProps('email')}
          placeholder="Your email"
          onKeyDown={getHotkeyHandler([['Enter', handleLogin]])}
        />

        <Input
          label="Password"
          type="password"
          autoComplete="current-password"
          style={{ flex: 1 }}
          {...form.getInputProps('password')}
          placeholder="Your password"
        />

        <Tooltip content="Email us at alfredodling@gmail.com and we'll reset your password.">
          <p className="px-0 h-auto text-xs text-muted-foreground ml-1">
            Forgot password?
          </p>
        </Tooltip>

        <Button
          className="mt-3"
          isLoading={loading}
          onClick={handleLogin}
          disabled={!form.values.email || !form.values.password}
        >
          Enter
          <ArrowRight className="h-4 w-4 ml-1" />
        </Button>
      </Column>
    </>
  )

  return (
    <div className="flex items-center justify-center py-12">
      <Card className="mx-auto grid gap-6 w-[350px] p-6 glass-thick">
        <Tabs defaultValue="signup">
          <TabsList className="grid grid-cols-2">
            <TabsTrigger value="signup">Signup</TabsTrigger>
            <TabsTrigger value="login">Login</TabsTrigger>
          </TabsList>

          <TabsContent value="signup">{Signup()}</TabsContent>
          <TabsContent value="login">{Login()}</TabsContent>
        </Tabs>
      </Card>
    </div>
  )
}
