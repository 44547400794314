import { zodResolver } from '@hookform/resolvers/zod'
import { Save, Trash2, XCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { paths } from 'src/routes/paths'
import { FormInput, FormWrapper } from 'src/shared/components/FormWrapper'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/shared/components/ui'
import { z } from 'zod'

const formSchema = z.object({
  name: z.string().min(2).max(50),
  color: z.string(),
  backgroundImage: z.string(),
})

const colorPalette = [
  '#FF5252',
  '#00E5FF',
  '#00B8D4',
  '#FF7043',
  '#64FFDA',
  '#EC407A',
  '#B2FF59',
  '#536DFE',
  '#FFD740',
  '#00BFA5',
  '#7C4DFF',
  '#40C4FF',
  '#69F0AE',
  '#FF5722',
  '#8D6E63',
]

const backgroundImages = [
  'https://images.unsplash.com/photo-1506744038136-46273834b3fb?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1472214103451-9374bd1c798e?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1490604001847-b712b0c2f967?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1475924156734-496f6cac6ec1?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1502481851512-e9e2529bfbf9?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1505765050516-f72dcac9c60e?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1501854140801-50d01698950b?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1441974231531-c6227db76b6e?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1426604966848-d7adac402bff?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  // Modern gradient backgrounds
  'https://images.unsplash.com/photo-1557682250-33bd709cbe85?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1557682224-5b8590cd9ec5?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1558470598-a5dda9640f68?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
]

export const SettingsSheet = ({ boardId }) => {
  const navigate = useNavigate()
  const { board, updateBoard, deleteBoard } = useBoardsApi({
    boardId,
  })
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const onSubmitBoardSettings = (values: z.infer<typeof formSchema>) => {
    updateBoard.mutate({
      id: boardId || '',
      name: values.name,
      color: values.color,
      background_image_url: values.backgroundImage,
    })
  }

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      color: '',
      backgroundImage: '',
    },
  })

  useEffect(() => {
    if (board?.data) {
      methods.setValue('name', board?.data?.name || '')
      methods.setValue('color', board?.data?.color || '')
      methods.setValue(
        'backgroundImage',
        board?.data?.background_image_url || ''
      )
    }
  }, [board?.data])

  const handleDeleteBoard = () => {
    deleteBoard.mutate(boardId || '')
    navigate(paths.boards)
    setIsDeleteDialogOpen(false)
  }

  return (
    <Card className="w-full justify-center items-center">
      <Column className="gap-2">
        <FormWrapper onSubmit={onSubmitBoardSettings} methods={methods}>
          <FormInput name="name" label="Board name" methods={methods} />
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Color
            </label>

            <div className="flex flex-wrap gap-2">
              {colorPalette.map((color) => (
                <button
                  key={color}
                  type="button"
                  onClick={() => methods.setValue('color', color)}
                  className={`w-8 h-8 rounded-full focus:outline-none ${
                    methods.watch('color') === color
                      ? 'ring-2 ring-offset-2 ring-gray-500'
                      : ''
                  }`}
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Background
            </label>

            <div className="flex flex-wrap gap-2">
              <button
                type="button"
                onClick={() => methods.setValue('backgroundImage', '')}
                className={`w-12 h-12 rounded-md focus:outline-none flex items-center justify-center ${
                  methods.watch('backgroundImage') === ''
                    ? 'ring-2 ring-offset-2 ring-gray-500'
                    : ''
                }`}
                style={{ backgroundColor: '#f0f0f0' }}
              >
                <XCircle className="w-6 h-6 text-gray-500" />
              </button>

              {backgroundImages.map((image) => (
                <button
                  key={image}
                  type="button"
                  onClick={() => methods.setValue('backgroundImage', image)}
                  className={`w-12 h-12 rounded-md focus:outline-none bg-cover bg-center ${
                    methods.watch('backgroundImage') === image
                      ? 'ring-2 ring-offset-2 ring-gray-500'
                      : ''
                  }`}
                  style={{ backgroundImage: `url(${image})` }}
                />
              ))}
            </div>
          </div>

          <Row className="justify-between">
            <Button type="submit">
              <Save className="w-4 h-4 mr-2" />
              Save
            </Button>

            <Dialog
              open={isDeleteDialogOpen}
              onOpenChange={setIsDeleteDialogOpen}
            >
              <DialogTrigger asChild>
                <Button variant="outline" className="w-fit">
                  <Trash2 className="w-4 h-4 mr-2 text-red-500" />
                  Remove board
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    Are you sure you want to delete this board?
                  </DialogTitle>
                  <DialogDescription>
                    This action cannot be undone. This will permanently delete
                    the board and all data.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    variant="outline"
                    onClick={() => setIsDeleteDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={handleDeleteBoard}>
                    Delete
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </Row>
        </FormWrapper>
      </Column>
    </Card>
  )
}
