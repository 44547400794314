import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useWidgetApi } from 'src/api/hooks/apiWidget'
import { useGetTier } from 'src/api/utils/useGetTier'
import { FormInput, FormWrapper } from 'src/shared/components/FormWrapper'
import { Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Separator,
  Tooltip,
} from 'src/shared/components/ui'
import { DialogHeader } from 'src/shared/components/ui/dialog'
import { useAuth } from 'src/shared/hooks/authProvider'
import { z } from 'zod'

import { BoardCard } from './components/BoardCard'

const formSchema = z.object({
  name: z.string().min(2).max(50),
  color: z.string(),
})

export const BoardsPage = () => {
  const { orgId } = useOrgIdApi()
  const { session } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { addBoard, boards } = useBoardsApi({ orgId })
  const { createWidget } = useWidgetApi()
  const { numberOfLTDs } = useGetTier()

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      color: '#FF5252',
    },
  })

  const addBoardSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await addBoard.mutateAsync({
      name: values.name,
      color: values.color,
      org_id: orgId || '',
      profile_id: session?.user.id || '',
    })

    if (result) {
      const newBoardId = result

      await createWidget.mutateAsync({
        board_id: newBoardId,
        chat_name: '',
        feature_requests: true,
        news: true,
        organization_id: orgId || '',
      })
    }

    setIsModalOpen(false)
    methods.reset({
      name: '',
      color: '#FF5252',
    })
  }

  const colorPalette = [
    '#1A237E', // Deep Indigo
    '#0D47A1', // Dark Blue
    '#01579B', // Dark Cyan
    '#004D40', // Dark Teal
    '#1B5E20', // Dark Green
    '#33691E', // Olive Green
    '#F57F17', // Dark Amber
    '#E65100', // Dark Orange
    '#BF360C', // Deep Orange
    '#3E2723', // Dark Brown
    '#263238', // Blue Grey
    '#880E4F', // Dark Pink
    '#4A148C', // Deep Purple
    '#311B92', // Deep Indigo
    '#1A237E', // Another Deep Indigo
  ]

  return (
    <>
      <Card className="w-full h-full p-6">
        <Row className="justify-between items-center w-full">
          <h1 className="text-2xl font-normal">Boards</h1>

          <Tooltip content="You can only have one board with free tier">
            <div>
              <Button
                disabled={Boolean(
                  boards?.data && boards?.data?.length > 0 && numberOfLTDs === 0
                )}
                onClick={() => setIsModalOpen(true)}
              >
                Add new board
              </Button>
            </div>
          </Tooltip>
        </Row>

        {!boards?.data?.length && (
          <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
            <p className="text-lg text-gray-600">
              You don't have any boards yet.
            </p>
            <p className="text-md text-gray-500 mt-2">
              Click "Add new board" to get started!
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          {boards?.data?.map((board) => <BoardCard board={board} />)}
        </div>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-lg">
          <DialogHeader>Add new board</DialogHeader>
          <Separator />

          <FormWrapper onSubmit={addBoardSubmit} methods={methods}>
            <FormInput
              label="Name"
              placeholder="Project name"
              name="name"
              methods={methods}
            />

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Color
              </label>
              <div className="flex flex-wrap gap-2">
                {colorPalette.map((color) => (
                  <button
                    key={color}
                    type="button"
                    onClick={() => methods.setValue('color', color)}
                    className={`w-8 h-8 rounded-full focus:outline-none ${
                      methods.watch('color') === color
                        ? 'ring-2 ring-offset-2 ring-gray-500'
                        : ''
                    }`}
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            </div>

            <Button
              type="submit"
              style={{
                marginTop: '1rem',
              }}
            >
              Add board
            </Button>
          </FormWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}
