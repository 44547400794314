import { Column } from 'src/shared/components/Semantic/all'
import { useAuth } from 'src/shared/hooks/authProvider'

import { BoardPage } from '../Board'
import { LoginOverlay } from './LoginScreen'

export default function PublicBoardPage() {
  const { session, loading } = useAuth()

  return (
    <Column className="h-screen flex items-center justify-center p-4">
      <div className="relative">
        {!loading && !session?.user && (
          <Column
            c
            className="absolute z-40 p-4 rounded-xl bg-black bg-opacity-30"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <LoginOverlay />
          </Column>
        )}

        <BoardPage />
      </div>
    </Column>
  )
}
