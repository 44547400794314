import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

export type InsertLtdCode = Database['public']['Tables']['ltd_codes']['Insert']
export type UpdateLtdCode = Database['public']['Tables']['ltd_codes']['Update']

export const useLtdCodesApi = ({
  organization_id,
}: {
  organization_id?: string
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const { data: ltdCodes } = useQuery({
    queryKey: ['ltd_codes', organization_id],
    queryFn: async () =>
      await supabase
        .from('ltd_codes')
        .select('*')
        .eq('organization_id', organization_id!)
        .order('created_at', { ascending: false }),
    enabled: !!organization_id,
  })

  const addCode = useMutation({
    mutationFn: async (insertCode: InsertLtdCode) =>
      await supabase.from('ltd_codes').insert([insertCode]),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ltd_codes', organization_id],
      })
    },
  })

  const updateCode = useMutation({
    mutationFn: async (updateCode: UpdateLtdCode) =>
      await supabase
        .from('ltd_codes')
        .update(updateCode)
        .eq('code', updateCode.code!),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ltd_codes', organization_id],
      })
    },
  })

  const deleteCode = useMutation({
    mutationFn: async (id: number) =>
      await supabase.from('ltd_codes').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ltd_codes', organization_id],
      })
    },
  })

  return {
    ltdCodes,
    addCode,
    updateCode,
    deleteCode,
  }
}
