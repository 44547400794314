import { Upload, X } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSupabaseClient } from 'src/api/supabase'
import { Button, Image, ScrollArea } from 'src/shared/components/ui'

export default function FileUpload({
  uploadedFiles,
  maxFiles,
  maxSize,
  onUploadComplete,
}) {
  const [files, setFiles] = useState<File[]>([])
  const supabase = useSupabaseClient()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    maxFiles,
    maxSize,
  })

  const removeFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
  }

  const uploadFiles = async () => {
    const uploadedUrls: string[] = []

    for (const file of files) {
      const urlSafeFileName = file.name.replace(/[^a-zA-Z0-9-_.]/g, '')
      const fileName = `${Date.now()}_${urlSafeFileName}`

      const { data, error } = await supabase.storage
        .from('attachments')
        .upload(fileName, file)

      if (error) {
        console.error('Error uploading file:', error)
      } else {
        console.log('File uploaded successfully:', data)
        const { data: publicUrlData } = supabase.storage
          .from('attachments')
          .getPublicUrl(fileName)

        if (publicUrlData) {
          uploadedUrls.push(publicUrlData.publicUrl)
        }
      }
    }

    setFiles([])

    const imageUrls = [...uploadedFiles, ...uploadedUrls].join(',')
    onUploadComplete(imageUrls)
  }

  return (
    <div className="w-full rounded-md">
      <div
        {...getRootProps()}
        className={`p-8 border border-dashed rounded-lg text-center cursor-pointer transition-colors bg-white ${
          isDragActive
            ? 'border-primary bg-primary/10'
            : 'border-gray-300 hover:border-primary'
        }`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-6 w-6 text-gray-400" />

        <p className="mt-2 text-sm text-gray-600">
          Drop images here or click to select images
        </p>
      </div>

      {files.length > 0 && (
        <ScrollArea className="mt-4 h-[90px] bg-white p-2 rounded-md">
          <ul className="space-y-2 ">
            {files?.map((file, index) => (
              <li
                key={index + file.name}
                className="flex items-center justify-between p-2 bg-gray-100 rounded"
              >
                <Image
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="w-10 h-10 rounded-sm object-cover"
                />
                <span className="text-sm truncate">{file.name}</span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => removeFile(file)}
                  aria-label={`Remove ${file.name}`}
                >
                  <X className="h-4 w-4" />
                </Button>
              </li>
            ))}
          </ul>
        </ScrollArea>
      )}

      {files.length > 0 && (
        <Button className="mt-4" onClick={uploadFiles}>
          Upload {files.length} image{files.length === 1 ? '' : 's'}
        </Button>
      )}
    </div>
  )
}
