import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { paths } from 'src/routes/paths'

import logoImage from './logo.png'

export const Logo = () => {
  return (
    <Link
      to={paths.boards}
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20"
    >
      <img src={logoImage} alt="Logo" className="h-8 w-auto rounded-full" />

      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="font-normal text-black dark:text-white whitespace-pre"
      >
        GetUserVotes
      </motion.span>
    </Link>
  )
}
