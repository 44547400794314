import { Image } from 'src/shared/components/ui'
import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'src/shared/components/ui/carousel'
import { Carousel } from 'src/shared/components/ui/carousel'
import { Dialog } from 'src/shared/components/ui/dialog'
import { DialogContent } from 'src/shared/components/ui/dialog'

export const ImagesCarousel = ({
  images,
  isCarouselOpen,
  setIsCarouselOpen,
}) => {
  return (
    <Dialog open={isCarouselOpen} onOpenChange={setIsCarouselOpen}>
      <DialogContent className="max-w-4xl h-[80vh]">
        <Carousel>
          <CarouselContent>
            {images?.map((image, index) => (
              <CarouselItem
                key={index}
                className="flex items-center justify-center"
              >
                <Image
                  className="object-contain"
                  alt={`Image ${index + 1}`}
                  src={image}
                />
              </CarouselItem>
            ))}
          </CarouselContent>

          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}
