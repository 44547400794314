import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CircleCheck } from 'lucide-react'
import { useSupabaseClient } from 'src/api/supabase'
import { Row } from 'src/shared/components/Semantic/all'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

export type InsertBoard = Database['public']['Tables']['boards']['Insert']
export type UpdateBoard = Partial<
  Database['public']['Tables']['boards']['Update']
>
export type Board = Database['public']['Tables']['boards']['Row']

export type InsertBoardColumn =
  Database['public']['Tables']['board_columns']['Insert']
export type UpdateBoardColumn = Partial<
  Database['public']['Tables']['board_columns']['Update']
>
export type BoardColumn = Database['public']['Tables']['board_columns']['Row']

export const useBoardsApi = ({
  orgId,
  boardId,
}: {
  orgId?: any
  boardId?: any
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const addBoard = useMutation({
    mutationFn: async ({ name, org_id, color, profile_id }: InsertBoard) => {
      const { data, error } = await supabase
        .from('boards')
        .insert([
          {
            org_id,
            name,
            color,
            profile_id,
          },
        ])
        .select()

      if (error) throw error
      return data[0].id
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boards'] })
    },
  })

  const deleteBoard = useMutation({
    mutationFn: async (id: any) =>
      await supabase.from('boards').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boards'] })
    },
  })

  const updateBoard = useMutation({
    mutationFn: async ({ id, ...updateData }: UpdateBoard) =>
      await supabase.from('boards').update(updateData).eq('id', id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boards'] })

      toast({
        variant: 'success',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <CircleCheck className="h-4 w-4" />
            Updated
          </Row>
        ),
      })
    },
  })

  const { data: boards, refetch: refetchBoards } = useQuery({
    queryKey: ['boards', orgId],
    queryFn: async () => {
      let query = supabase
        .from('boards')
        .select(`*`)
        .order('created_at', { ascending: false })
        .eq('org_id', orgId)

      return await query
    },
    enabled: !!orgId,
  })

  const { data: board } = useQuery({
    queryKey: ['boards', boardId],
    queryFn: async () => {
      let query = supabase.from('boards').select('*').eq('id', boardId)
      return await query.single()
    },
    enabled: !!boardId,
  })

  const addBoardColumn = useMutation({
    mutationFn: async ({ name, board_id, order }: InsertBoardColumn) => {
      const { data, error } = await supabase
        .from('board_columns')
        .insert([{ name, board_id, order }])
        .select()

      if (error) throw error
      return data[0].id
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boardColumns'] })
    },
  })

  const deleteBoardColumn = useMutation({
    mutationFn: async (id: number) =>
      await supabase.from('board_columns').delete().eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boardColumns'] })
    },
  })

  const updateBoardColumn = useMutation({
    mutationFn: async ({ id, ...updateData }: UpdateBoardColumn) =>
      await supabase.from('board_columns').update(updateData).eq('id', id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['boardColumns'] })
    },
  })

  const { data: boardColumns, refetch: refetchBoardColumns } = useQuery({
    queryKey: ['boardColumns', boardId],
    queryFn: async () => {
      let query = supabase
        .from('board_columns')
        .select('*')
        .order('created_at', { ascending: true })
        .eq('board_id', boardId)
      return await query
    },
    enabled: !!boardId,
  })

  return {
    addBoard,
    deleteBoard,
    updateBoard,
    boards,
    board,
    refetchBoards,

    addBoardColumn,
    deleteBoardColumn,
    updateBoardColumn,
    boardColumns,
    refetchBoardColumns,
  }
}
