import * as React from 'react'

export const Column = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    c?: boolean
  }
>((props, ref) => (
  <div
    {...props}
    ref={ref}
    className={`${props.className} ${props.c ? 'col-c' : 'col'}`}
  >
    {props.children}
  </div>
))

export const Row = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    c?: boolean
  }
>((props, ref) => (
  <div
    {...props}
    ref={ref}
    className={`${props.className} ${props.c ? 'row-c' : 'row'}`}
  >
    {props.children}
  </div>
))
