import { Sparkle } from 'lucide-react'
import React from 'react'

import { Tooltip } from './shared/components/ui'
import { cn } from './shared/components/ui/utils'

const BoardWidget: React.FC = () => {
  const handleClick = () => {
    window.open(
      'https://app.getuservotes.com/public-board/a30a3120-1bbf-4f89-8061-ef26cfa7f84b',
      '_blank'
    )
  }

  return (
    <Tooltip content="Add a feature suggestion">
      <div
        className={cn(
          'fixed bottom-6 right-6 w-12 h-12 rounded-full glass',
          'flex items-center justify-center cursor-pointer transition-all duration-300',
          'hover:bg-opacity-30 hover:scale-110'
        )}
        onClick={handleClick}
      >
        <Sparkle className="text-blue-500" size={24} />
      </div>
    </Tooltip>
  )
}

export default BoardWidget
