import { useLtdCodesApi } from '../hooks/apiLtdCodes'
import { useOrgIdApi } from '../hooks/apiOrgId'

export const useGetTier = () => {
  const { orgId } = useOrgIdApi()
  const { ltdCodes } = useLtdCodesApi({ organization_id: orgId })

  return {
    numberOfLTDs: ltdCodes?.data?.length || 0,
  }
}
