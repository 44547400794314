import { paths } from 'src/routes/paths'

export const getCodeSnippets = (boardId: string) => {
  const widgetCode = `
  <!-- Getuservotes BEGIN -->
    <div data-symbol="${boardId}" id="getuservotes"></div>
    <link href="https://instighto-server-production.up.railway.app/WidgetBundle.css" rel="stylesheet" />
    <script src="https://instighto-server-production.up.railway.app/WidgetBundle.js"></script>
  <!-- Getuservotes END -->
   `

  const embedCode = `
  <iframe
    src="${process.env.REACT_APP_FE_SERVER_URL}${paths.publicBoard}/${boardId}"
    style="height: 100%;width: 100%;border: 0;borderRadius: 0.75rem;"
    title="Instighto News"
  />
  `

  const embedButtonCode = `
    <button
      onClick={() => window.open(${paths.publicBoard}/${boardId}, '_blank')}>
      Add feature suggestion
    </button>
  `

  return { widgetCode, embedCode, embedButtonCode }
}
