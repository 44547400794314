export const paths = {
  loginSignUp: '/login-signUp',

  cookieConsent: '/cookieConsent',
  privacyPolicy: '/privacyPolicy',
  termsOfService: '/termsOfService',

  boards: '/boards',
  news: '/news',
  settingsPage: '/settings',
  onboarding: '/onboarding',
  widgetsPage: '/widgets',
  publicBoard: '/public-board',
}
