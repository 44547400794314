import { useNavigate } from 'react-router-dom'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { paths } from 'src/routes/paths'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui'

export const lightenColor = (color: string, amount: number) => {
  const c = color.replace('#', '')
  const rgb = [
    parseInt(c.substr(0, 2), 16),
    parseInt(c.substr(2, 2), 16),
    parseInt(c.substr(4, 2), 16),
  ]
  const lightened = rgb.map((c) => Math.min(255, c + Math.round(amount * 1.5)))
  return `#${lightened.map((c) => c.toString(16).padStart(2, '0')).join('')}`
}

export const BoardCard = ({ board }) => {
  const navigate = useNavigate()
  const { profile } = useProfilesApi(board.profile_id)
  const lightenedColor = lightenColor(board.color, 50)

  return (
    <Card
      key={board.id}
      className="min-h-[100px] shadow-md cursor-pointer hover:shadow-lg transition-shadow p-4 flex flex-col justify-between"
      style={{
        background: `linear-gradient(to right, ${board.color}, ${lightenedColor})`,
      }}
      onClick={() => navigate(paths.boards + '/' + board.id)}
    >
      <div className="text-white font-normal">{board.name}</div>

      <Column className="justify-end">
        <div className="text-white font-normal text-xs opacity-75">
          {new Date(board.created_at).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
          })}
        </div>

        <div className="text-white font-normal text-xs opacity-75">
          {profile?.data?.email}
        </div>
      </Column>
    </Card>
  )
}
