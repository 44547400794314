import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Clock, User, X } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFeatureSuggestionsApi } from 'src/api/hooks/apiFeatureSuggestions'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { useIsBoardOwner } from 'src/api/utils/functions'
import FileUpload from 'src/shared/components/FileUpload'
import RichText from 'src/shared/components/RichText'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Image, Input, Separator } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import {
  Sheet,
  SheetContent,
  SheetHeader,
} from 'src/shared/components/ui/sheet'
import { useAuth } from 'src/shared/hooks/authProvider'
import { z } from 'zod'

import { ChatSection } from './ChatSection'

dayjs.extend(relativeTime)

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  imageUrls: z.array(z.string()).optional(),
})

export const SuggestionSheet = ({
  featureSuggestionId,
  isModalOpen,
  setIsModalOpen,
  isBoardOwner,
}: {
  featureSuggestionId: number | null
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  isBoardOwner: boolean
}) => {
  const { board_id } = useParams()
  const { updateFeatureSuggestion, feature_suggestions } =
    useFeatureSuggestionsApi(board_id || '')
  const { session } = useAuth()
  const isMyBoard = useIsBoardOwner(board_id, session?.user.id)

  const featureSuggestion = feature_suggestions?.data?.find(
    (suggestion) => suggestion.id === featureSuggestionId
  )

  const { profile } = useProfilesApi(featureSuggestion?.profile_id || '')

  const isCreator = featureSuggestion?.profile_id === session?.user.id
  const canEdit = isBoardOwner || isCreator

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      imageUrls: [],
    },
  })

  useEffect(() => {
    if (featureSuggestion) {
      methods.reset({
        title: featureSuggestion.title || '',
        description: featureSuggestion.description || '',
        imageUrls: featureSuggestion.image_urls?.split(',') || [],
      })
    }
  }, [featureSuggestion])

  const suggestFeature = async () => {
    if (canEdit) {
      const { title, description } = methods.getValues()
      await updateFeatureSuggestion.mutateAsync({
        id: featureSuggestion?.id || 0,
        title,
        description,
        board_id: board_id || '',
        profile_id: featureSuggestion?.profile_id || '',
      })
      setIsModalOpen(false)
    }
  }

  const onUploadComplete = async (newImageUrls: string) => {
    if (canEdit) {
      await updateFeatureSuggestion.mutateAsync({
        id: featureSuggestion?.id || 0,
        image_urls: newImageUrls,
      })
      methods.setValue('imageUrls', [])
    }
  }

  return (
    <Sheet open={isModalOpen} onOpenChange={setIsModalOpen}>
      <SheetContent className="glass-thick" closeButton={false}>
        <SheetHeader className="mb-4">
          <Row className="gap-2 justify-end">
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            {canEdit && <Button onClick={suggestFeature}>Save</Button>}
          </Row>
        </SheetHeader>

        <Column className="gap-4">
          {canEdit ? (
            <Input placeholder="Title" {...methods.register('title')} />
          ) : (
            <h2 className="text-lg font-semibold">
              {featureSuggestion?.title}
            </h2>
          )}
          <Column className="gap-1">
            {canEdit ? (
              <Controller
                name="description"
                control={methods.control}
                render={({ field }) => (
                  <RichText
                    value={field.value || ''}
                    onChange={field.onChange}
                  />
                )}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: featureSuggestion?.description || '',
                }}
              />
            )}

            <Row className="gap-2 ml-1 mt-1">
              <Badge variant="outline">
                <Row className="items-center gap-1 py-1">
                  <Clock className="w-4 h-4" />
                  <h2>{dayjs(featureSuggestion?.created_at).toNow()}</h2>
                </Row>
              </Badge>

              <Badge variant="outline">
                <Row className="items-center gap-1 py-1">
                  <User className="w-4 h-4" />
                  <h2>{profile?.data?.email}</h2>
                </Row>
              </Badge>
            </Row>
          </Column>

          <Row className="gap-2">
            {featureSuggestion?.image_urls?.split(',').map((file, index) => (
              <div className="relative" key={index}>
                <Image src={file} alt={file} className="w-24 h-24 rounded-sm" />
                {canEdit && (
                  <Button
                    className="absolute top-2 right-2 h-6 w-6"
                    variant="destructive"
                    size="icon"
                    onClick={async () => {
                      const newImageUrls = featureSuggestion?.image_urls
                        ?.split(',')
                        .filter((url) => url !== file)
                        .join(',')

                      await updateFeatureSuggestion.mutateAsync({
                        id: featureSuggestion?.id || 0,
                        image_urls: newImageUrls,
                      })
                    }}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                )}
              </div>
            ))}
          </Row>

          {canEdit && (
            <Controller
              name="imageUrls"
              control={methods.control}
              render={({ field }) => (
                <FileUpload
                  onUploadComplete={onUploadComplete}
                  uploadedFiles={field.value}
                  maxFiles={5}
                  maxSize={10 * 1024 * 1024} // 10 MB
                />
              )}
            />
          )}
          <Separator className="my-0" />

          <ChatSection
            featureSuggestion={featureSuggestion}
            isMyBoard={isMyBoard}
            profile_id={session?.user.id}
          />
        </Column>
      </SheetContent>
    </Sheet>
  )
}
