import { Trello } from 'lucide-react'
import { paths } from 'src/routes/paths'

export const links = [
  {
    label: 'Boards',
    href: paths.boards,
    icon: (
      <Trello className="text-neutral-700 dark:text-neutral-200 h-5 w-5 flex-shrink-0" />
    ),
  },
]
