import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from 'src/api/supabase'
import { Database } from 'supabase/database.types'

export type InsertFeatureSuggestionChatMessage =
  Database['public']['Tables']['feature_suggestion_chat_messages']['Insert']
export type FeatureSuggestionChatMessage =
  Database['public']['Tables']['feature_suggestion_chat_messages']['Row']

export const useFeatureSuggestionChatMessagesApi = () => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const addFeatureSuggestionComment = useMutation({
    mutationFn: async ({
      type,
      user_email,
      message,
      feature_suggestion_id,
      profile_id,
    }: any) =>
      await supabase.from('feature_suggestion_chat_messages').upsert({
        type,
        user_email,
        message,
        feature_suggestion_id,
        profile_id,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['feature_suggestions'] })
    },
  })

  const updateFeatureSuggestionComment = useMutation({
    mutationFn: async ({ id, message }: any) =>
      await supabase
        .from('feature_suggestion_chat_messages')
        .update({
          message,
        })
        .eq('id', id),
  })

  const deleteFeatureSuggestionComment = useMutation({
    mutationFn: async (id: number) =>
      await supabase
        .from('feature_suggestion_chat_messages')
        .delete()
        .eq('id', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['feature_suggestions'] })
    },
  })

  return {
    addFeatureSuggestionComment,
    updateFeatureSuggestionComment,
    deleteFeatureSuggestionComment,
  }
}
