import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowRight, Bird, Crown, Save } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useLtdCodesApi } from 'src/api/hooks/apiLtdCodes'
import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useSupabaseClient } from 'src/api/supabase'
import { FormInput, FormWrapper } from 'src/shared/components/FormWrapper'
import { Column, Row } from 'src/shared/components/Semantic/all'
import { Button, Card, Input } from 'src/shared/components/ui'
import { Badge } from 'src/shared/components/ui/badge'
import { useAuth } from 'src/shared/hooks/authProvider'
import { useToast } from 'src/shared/hooks/use-toast'
import { z } from 'zod'

import { useOrgApi } from '../../api/hooks/apiOrgs'
import { useProfilesApi } from '../../api/hooks/apiProfiles'
import { paths } from '../../routes/paths'

const formSchema = z.object({
  first_name: z.string().min(2).max(50),
  last_name: z.string().min(2).max(50),
  email: z.string().email(),
  avatar_url: z.string().optional(),
})

export const SettingsPage = () => {
  const { session } = useAuth()
  const { orgId } = useOrgIdApi()
  const { profile, updateProfile } = useProfilesApi(session?.user?.id)
  const { organizations } = useOrgApi(orgId)
  const navigate = useNavigate()
  const supabase = useSupabaseClient()
  const [ltdCode, setLtdCode] = useState('')
  const { ltdCodes, updateCode } = useLtdCodesApi({ organization_id: orgId })

  const numberOfLTDs = ltdCodes?.data?.length || 0

  const { toast } = useToast()

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      avatar_url: '',
    },
  })

  useEffect(() => {
    const profile_ = profile?.data
    if (profile_) {
      methods.setValue('first_name', profile_.first_name || '')
      methods.setValue('last_name', profile_.last_name || '')
      methods.setValue('email', profile_.email || '')
      methods.setValue('avatar_url', profile_.avatar_url || '')
    }
  }, [profile?.data, organizations?.data])

  const onSubmitUserSettings = (values: z.infer<typeof formSchema>) =>
    updateProfile.mutate({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      avatar_url: values.avatar_url,
      id: session?.user.id!,
    })

  const handleLogout = async () => {
    await supabase.auth.signOut()
    navigate(paths.loginSignUp)
  }

  return (
    <>
      <Column c className="gap-4">
        <Card className="w-[700px] justify-center items-center">
          <Column className="gap-4 w-full">
            <Card className="bg-gradient-to-r from-indigo-500 to-pink-500">
              <h2 className="text-white text-lg font-semibold">Current plan</h2>

              <Row className="gap-2 mt-3">
                {numberOfLTDs > 0 && (
                  <Badge className="bg-white text-black py-2 px-3 rounded-full">
                    <Crown className="h-4 w-4 text-yellow-500 mr-1" />
                    LDT with {numberOfLTDs}{' '}
                    {numberOfLTDs === 1 ? 'code' : 'codes'}
                  </Badge>
                )}

                {numberOfLTDs === 0 && (
                  <Badge className="bg-white text-black py-2 px-3 rounded-full">
                    <Bird className="h-4 w-4 mr-1" />
                    Free tier
                  </Badge>
                )}
              </Row>
            </Card>

            <Row className="gap-2 items-center">
              <Input
                className="rounded-full"
                name="ltd_code"
                value={ltdCode}
                placeholder="If you have a code, enter it here"
                onChange={(e) => setLtdCode(e.target.value)}
              />

              <Button
                variant={'outline'}
                onClick={async () => {
                  await updateCode
                    .mutateAsync({
                      code: ltdCode,
                      organization_id: orgId || '',
                      redeemed: true,
                    })
                    .then(() => {
                      setLtdCode('')
                      toast({
                        title: 'LTD code redeemed! 🎉',
                      })
                    })
                }}
              >
                Redeem
                <ArrowRight className="w-4 h-4 ml-2" />
              </Button>
            </Row>

            {/* <Button
              className="w-fit mt-4 ml-auto"
              onClick={() => {
                window.open(
                  `https://billing.stripe.com/p/login/9AQ5lBfd9f1G8Q8eUU?prefilled_email=${profile?.data?.email}`,
                  '_blank'
                )
              }}
            >
              <CreditCard className="h-4 w-4 mr-2" />
              Upgrade / Manage subscription
            </Button> */}
          </Column>
        </Card>

        <Card className="w-[700px] justify-center items-center">
          <h2 className="text-lg font-semibold">User settings</h2>

          <Column className="gap-2 w-full mt-4">
            <FormWrapper onSubmit={onSubmitUserSettings} methods={methods}>
              <FormInput
                name="first_name"
                label="First name"
                methods={methods}
              />
              <FormInput name="last_name" label="Last name" methods={methods} />
              <FormInput
                name="email"
                label="Email"
                methods={methods}
                disabled
              />
              <FormInput
                name="avatar_url"
                label="Avatar URL"
                methods={methods}
              />

              <Button style={{ marginTop: '1rem' }} type="submit">
                <Save className="w-4 h-4 mr-2" />
                Save
              </Button>
            </FormWrapper>
          </Column>
        </Card>

        <Card className="w-[700px] justify-center items-center">
          <h2 className="text-lg font-semibold mb-2">Other</h2>

          <Button onClick={handleLogout} className="mt-4" variant={'outline'}>
            Log out
            <ArrowRight className="h-4 w-4 ml-2" />
          </Button>
        </Card>
      </Column>
    </>
  )
}
