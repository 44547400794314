import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App, queryClient } from './App'
import BoardWidget from './BoardWidget'
import { SupabaseProvider } from './api/supabase'
import './global.css'
import reportWebVitals from './reportWebVitals'
import { Toaster } from './shared/components/ui/toaster'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SupabaseProvider>
        <Toaster />
        <App />
      </SupabaseProvider>

      {/* <ReactQueryDevtools /> */}
      <BoardWidget />
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
