import {
  ArrowLeft,
  ArrowRight,
  MoreHorizontal,
  PlusCircle,
  Trash,
  X,
} from 'lucide-react'
import { KeyboardEvent, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useBoardsApi } from 'src/api/hooks/apiBoards'
import { useFeatureSuggestionsApi } from 'src/api/hooks/apiFeatureSuggestions'
import { useIsBoardOwner } from 'src/api/utils/functions'
import { Column, Row } from 'src/shared/components/Semantic/all'
import {
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  ScrollArea,
} from 'src/shared/components/ui'
import { ScrollBar } from 'src/shared/components/ui/scroll-area'
import { useAuth } from 'src/shared/hooks/authProvider'

import { Header } from './components/Header'
import { Suggestion } from './components/Suggestion'

export const BoardPage = () => {
  const { board_id } = useParams()
  const { session } = useAuth()
  const {
    board,
    boardColumns,
    addBoardColumn,
    updateBoardColumn,
    deleteBoardColumn,
  } = useBoardsApi({ boardId: board_id })
  const { feature_suggestions, updateFeatureSuggestion, addFeatureSuggestion } =
    useFeatureSuggestionsApi(board_id || '')

  const isBoardOwner = useIsBoardOwner(
    board?.data?.profile_id,
    session?.user?.id || ''
  )

  const [addingCard, setAddingCard] = useState({})
  const [newCardTitle, setNewCardTitle] = useState('')
  const [newColumnName, setNewColumnName] = useState('')

  const textColor = board?.data?.background_image_url
    ? 'text-[#ffffffb8]'
    : 'text-default-300'

  const onDragEnd = (result) => {
    if (!result.destination || !isBoardOwner) return

    const { source, destination, draggableId } = result
    const newColumnId = parseInt(destination.droppableId)

    if (source.droppableId === destination.droppableId) {
      handleUpdateCardOrder(
        newColumnId,
        parseInt(draggableId),
        destination.index
      )
    } else {
      updateFeatureSuggestion.mutate({
        id: parseInt(draggableId),
        board_column_id: newColumnId,
        order: destination.index,
      })
    }
  }

  const handleUpdateCardOrder = (
    columnId: number,
    cardId: number,
    newIndex: number
  ) => {
    const column = boardColumns?.data?.find((col) => col.id === columnId)
    if (!column) return

    const cards = feature_suggestions?.data
      ?.filter((card) => card.board_column_id === columnId)
      .sort((a, b) => (a.order || 0) - (b.order || 0))

    if (!cards) return

    const updatedCards = [...cards]
    const [movedCard] = updatedCards.splice(
      cards.findIndex((card) => card.id === cardId),
      1
    )
    updatedCards.splice(newIndex, 0, movedCard)

    updatedCards.forEach((card, index) => {
      updateFeatureSuggestion.mutate({
        id: card.id,
        order: index,
      })
    })
  }

  const handleAddCard = (columnId) => {
    setAddingCard((prev) => ({ ...prev, [columnId]: true }))
  }

  const handleAddSuggestion = async (columnId) => {
    if (newCardTitle.trim()) {
      await addFeatureSuggestion.mutateAsync({
        title: newCardTitle,
        board_id: board_id || '',
        profile_id: session?.user?.id || '',
        board_column_id: columnId,
      })
    }

    setNewCardTitle('')
    setAddingCard((prev) => ({ ...prev, [columnId]: false }))
  }

  const handleInputBlur = (columnId) => {
    handleAddSuggestion(columnId)
  }

  const handleInputChange = (e) => {
    setNewCardTitle(e.target.value)
  }

  const handleKeyPress = (
    e: KeyboardEvent<HTMLInputElement>,
    columnId: string
  ) => {
    if (e.key === 'Enter') {
      handleAddSuggestion(columnId)
    }
  }

  const handleAddColumn = async () => {
    if (newColumnName.trim()) {
      const newColumn = await addBoardColumn.mutateAsync({
        name: newColumnName,
        board_id: board_id,
        order: (boardColumns?.data?.length || 0) + 1,
      })
      setNewColumnName('')
      handleAddCard(newColumn)
    }
  }

  const handleColumnInputBlur = () => {
    handleAddColumn()
  }

  const handleColumnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddColumn()
    }
  }

  const handleUpdateColumnOrder = (
    columnId: number,
    direction: 'left' | 'right'
  ) => {
    if (!isBoardOwner) return

    const sortedColumns = boardColumns?.data?.sort(
      (a, b) => (a.order || 0) - (b.order || 0)
    )
    const currentColumnIndex = sortedColumns?.findIndex(
      (col) => col.id === columnId
    )

    if (currentColumnIndex !== undefined && currentColumnIndex !== -1) {
      const newIndex =
        direction === 'left' ? currentColumnIndex - 1 : currentColumnIndex + 1

      if (newIndex >= 0 && newIndex < (sortedColumns?.length || 0)) {
        const updatedColumns = sortedColumns ? [...sortedColumns] : []

        if (updatedColumns.length > 0) {
          const [movedColumn] = updatedColumns.splice(currentColumnIndex, 1)
          updatedColumns.splice(newIndex, 0, movedColumn)

          updatedColumns.forEach((col, index) => {
            updateBoardColumn.mutate({ id: col.id, order: index })
          })
        }
      }
    }
  }

  const ColumnHeader = ({ column }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedName, setEditedName] = useState(column.name)

    const handleEditStart = () => {
      if (isBoardOwner) {
        setIsEditing(true)
      }
    }

    const handleEditSave = () => {
      if (isBoardOwner) {
        setIsEditing(false)
        updateBoardColumn.mutate({ id: column.id, name: editedName })
      }
    }

    return (
      <Row className="p-4 justify-between items-center rounded-xl glass-noshadow">
        <Row c className="gap-2">
          {isEditing && isBoardOwner ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleEditSave}
              onKeyPress={(e) => e.key === 'Enter' && handleEditSave()}
              className={`font-semibold ml-1 text-sm ${textColor} bg-transparent border-none focus:outline-none`}
              autoFocus
            />
          ) : (
            <h2
              className={`font-semibold ml-1 text-sm ${textColor} ${
                isBoardOwner ? 'cursor-pointer' : ''
              }`}
              onDoubleClick={handleEditStart}
            >
              {column.name}
            </h2>
          )}

          {isBoardOwner && (
            <Row className="gap-1">
              <ArrowLeft
                className={`w-4 h-4 cursor-pointer ${textColor}`}
                onClick={() => handleUpdateColumnOrder(column.id, 'left')}
              />
              <ArrowRight
                className={`w-4 h-4 cursor-pointer ${textColor}`}
                onClick={() => handleUpdateColumnOrder(column.id, 'right')}
              />
            </Row>
          )}
        </Row>

        {isBoardOwner && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <MoreHorizontal
                className={`w-4 h-4 cursor-pointer ${textColor}`}
              />
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => deleteBoardColumn.mutate(column.id)}
              >
                <Trash className="w-4 h-4 mr-2" />
                Delete column
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Row>
    )
  }

  const renderFeatureSuggestions = (column) =>
    feature_suggestions?.data
      ?.filter(
        (featureSuggestion) => featureSuggestion.board_column_id === column.id
      )
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map((featureSuggestion, index) => (
        <Draggable
          key={featureSuggestion.id}
          draggableId={featureSuggestion.id.toString()}
          index={index}
          isDragDisabled={!isBoardOwner}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Suggestion
                textColor={textColor}
                boardId={board?.data?.id}
                featureSuggestion={featureSuggestion as any}
                profile_id={session?.user?.id || ''}
                isBoardOwner={isBoardOwner}
              />
            </div>
          )}
        </Draggable>
      ))

  const renderAddCard = (column) =>
    addingCard[column.id] ? (
      <div className="relative">
        <Input
          placeholder="Add something.."
          onBlur={() => handleInputBlur(column.id)}
          onChange={handleInputChange}
          onKeyDown={(e) => handleKeyPress(e, column.id.toString())}
          value={newCardTitle}
          autoFocus
          className={
            board?.data?.background_image_url
              ? 'text-white bg-opacity-20 bg-black border-none'
              : ''
          }
        />
        <Button
          onClick={() =>
            setAddingCard((prev) => ({ ...prev, [column.id]: false }))
          }
          className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${textColor}`}
          size="sm"
          variant="ghost"
        >
          <X className="w-4 h-4" />
        </Button>
      </div>
    ) : (
      <Button
        onClick={() => handleAddCard(column.id)}
        className={`self-start ${textColor}`}
        size="sm"
        variant="light"
        style={{ color: textColor }}
      >
        <PlusCircle className="w-4 h-4 mr-2" />
        Add a card
      </Button>
    )

  const renderAddColumn = () =>
    isBoardOwner && (
      <div className="h-fit w-[260px] rounded-lg">
        <Input
          placeholder="New list name"
          value={newColumnName}
          onChange={(e) => setNewColumnName(e.target.value)}
          onBlur={handleColumnInputBlur}
          onKeyDown={handleColumnKeyPress}
          className={
            board?.data?.background_image_url
              ? 'text-white bg-opacity-40 bg-black border-none'
              : ''
          }
        />

        {newColumnName && (
          <Button
            onClick={handleAddColumn}
            size="sm"
            variant="light"
            className={`mt-2 ${textColor}`}
            style={{ color: textColor }}
          >
            <PlusCircle className="w-4 h-4 mr-2" />
            Add a list
          </Button>
        )}
      </div>
    )

  return (
    <Card className="h-full relative">
      {board?.data?.background_image_url && (
        <>
          <div
            className="absolute inset-0 bg-cover bg-center rounded-xl"
            style={{
              backgroundImage: board?.data?.background_image_url
                ? `url(${board?.data?.background_image_url})`
                : 'none',
            }}
          />
          <div className="absolute inset-0 bg-black opacity-50 rounded-xl" />
        </>
      )}

      <div className="relative z-10">
        <Column className="w-full h-[77vh]">
          <Header
            textColor={textColor}
            board={board?.data as any}
            isBoardOwner={isBoardOwner}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <ScrollArea className="w-[1200px]">
              <div className="flex gap-4 mt-4">
                {boardColumns?.data
                  ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((column, i) => (
                    <Droppable droppableId={[column.id]} key={column.id}>
                      {(provided) => (
                        <div
                          className="h-fit max-h-[69vh] overflow-y-auto relative"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Column className="w-[260px] gap-3">
                            <ColumnHeader column={column} />
                            {renderFeatureSuggestions(column)}
                            {(i === 0 || isBoardOwner) && renderAddCard(column)}
                            {provided.placeholder}
                          </Column>
                        </div>
                      )}
                    </Droppable>
                  ))}
                {renderAddColumn()}
              </div>
              <ScrollBar orientation="horizontal" className="h-4 opacity-0" />
            </ScrollArea>
          </DragDropContext>
        </Column>
      </div>
    </Card>
  )
}
