import { Column } from 'src/shared/components/Semantic/all'
import { Card, Code } from 'src/shared/components/ui'

import { getCodeSnippets } from './consts'

export const EmbedTab = ({ boardId }) => {
  const { embedCode, embedButtonCode } = getCodeSnippets(boardId || '')

  return (
    <Column c className="gap-4">
      <Card className="w-full justify-center items-center">
        <Column className="gap-2">
          <h2 className="text-lg font-normal mb-2">Embed board</h2>

          <Code>{embedCode}</Code>
          <p className="italic">Paste this code into your site</p>
        </Column>
      </Card>

      <Card className="w-full justify-center items-center">
        <Column className="gap-2">
          <h2 className="text-lg font-normal mb-2">Embed button</h2>

          <Code>{embedButtonCode}</Code>
          <p className="italic">Paste this code into your site</p>
        </Column>
      </Card>
    </Column>
  )
}
