import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'

import { useAuth } from '../../shared/hooks/authProvider'
import { paths } from '../paths'

export const ProtectedRoute = ({ component, ...args }: any) => {
  const { session, loading } = useAuth()
  const navigate = useNavigate()
  const { profile } = useProfilesApi(session?.user?.id)

  // const goToOnboarding = !profile?.data?.completed_onboarding

  // useMemo(() => {
  //   if (goToOnboarding) {
  //     navigate(paths.onboarding)
  //   }
  // }, [goToOnboarding])

  useEffect(() => {
    if (!loading && !session?.user) {
      navigate(paths.loginSignUp)
    }

    if (
      !loading &&
      !session?.user &&
      profile?.data?.profiles_organizations_roles[0].role_id === 2
    ) {
      navigate('https://getuservotes.com/')
    }
  }, [session, profile?.data])

  const Component = component

  return <Component {...args} />
}
