import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CircleCheck } from 'lucide-react'
import { useSupabaseClient } from 'src/api/supabase'
import { Row } from 'src/shared/components/Semantic/all'
import { useToast } from 'src/shared/hooks/use-toast'
import { Database } from 'supabase/database.types'

export type UpdateWidget = Database['public']['Tables']['widgets']['Update']
export type InsertWidget = Database['public']['Tables']['widgets']['Insert']

export const useWidgetApi = (boardId?: string) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const updateWidget = useMutation({
    mutationFn: async ({
      id,
      chat_name,
      feature_requests,
      news,
    }: UpdateWidget) =>
      await supabase
        .from('widgets')
        .update({
          chat_name,
          feature_requests,
          news,
        })
        .eq('id', id!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
      toast({
        variant: 'success',
        // @ts-ignore
        title: (
          <Row className="flex items-center gap-1">
            <CircleCheck className="h-4 w-4" />
            Updated
          </Row>
        ),
      })
    },
  })

  const createWidget = useMutation({
    mutationFn: async ({
      board_id,
      chat_name,
      feature_requests,
      news,
      organization_id,
    }: InsertWidget) =>
      await supabase.from('widgets').insert([
        {
          board_id,
          chat_name,
          feature_requests,
          news,
          organization_id,
        },
      ]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['widget'] })
    },
  })

  const { data: widget } = useQuery({
    queryKey: ['widget'],
    queryFn: async () =>
      await supabase
        .from('widgets')
        .select(`*`)
        .eq('board_id', boardId!)
        .single(),
    enabled: !!boardId,
  })

  return {
    updateWidget,
    createWidget,
    widget,
  }
}
