import * as React from 'react'
import { HexColorPicker } from 'react-colorful'

import { Column } from '../Semantic/all'
import { Label } from './label'
import { cn } from './utils'

export interface ColorPickerProps {
  label?: string
  color: string
  onChange: (color: string) => void
  className?: string
}

const ColorPicker = React.forwardRef<HTMLInputElement, ColorPickerProps>(
  ({ className, label, color, onChange, ...props }, ref) => {
    return (
      <div className={cn('flex flex-col space-y-2', className)}>
        {label && <Label>{label}</Label>}

        <Column className="gap-2 mt-3">
          <HexColorPicker color={color} onChange={onChange} />

          <input
            type="text"
            value={color}
            onChange={(e) => onChange(e.target.value)}
            className="w-32 px-2 py-1 text-sm border rounded"
            ref={ref}
            {...props}
          />
        </Column>
      </div>
    )
  }
)

ColorPicker.displayName = 'ColorPicker'

export { ColorPicker }
