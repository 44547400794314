import * as React from 'react'

import { Label } from './label'
import { cn } from './utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  description?: string
  endContent?: React.ReactNode
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, description, endContent, ...props }, ref) => {
    return (
      <div className="relative w-full">
        {label && <Label htmlFor="email">{label}</Label>}

        <div className="w-full">
          <input
            type={type}
            className={cn(
              'flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
              className
            )}
            ref={ref}
            {...props}
          />

          {endContent && (
            <div className="absolute inset-y-0 right-1 flex items-center">
              {endContent}
            </div>
          )}
        </div>

        {description && (
          <p className="text-xs text-muted-foreground ml-2 mt-1">
            {description}
          </p>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
