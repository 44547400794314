import { useOrgIdApi } from 'src/api/hooks/apiOrgId'
import { useProfilesApi } from 'src/api/hooks/apiProfiles'
import { Column } from 'src/shared/components/Semantic/all'
import { Card } from 'src/shared/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/shared/components/ui/table'

export const LeadsTab = ({ boardId }) => {
  const { profiles } = useProfilesApi()
  const { orgId } = useOrgIdApi()

  const filteredProfiles = profiles?.data?.filter(
    (profile) =>
      profile.profiles_organizations_roles[0].organization_id === orgId
  )

  return (
    <Column c className="gap-4">
      <Card className="w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {filteredProfiles?.map((profile) => (
              <TableRow key={profile.id}>
                <TableCell>{profile.email}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell className="font-bold">Total</TableCell>
              <TableCell className="font-bold">
                {filteredProfiles?.length || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Column>
  )
}
