import { createBrowserRouter } from 'react-router-dom'
import { BoardPage } from 'src/pages/Board'
import { BoardsPage } from 'src/pages/Boards'
import { CookieConsent } from 'src/pages/GDPR/CookieConsent'
import { PrivacyPolicy } from 'src/pages/GDPR/PrivacyPolicy'
import { TermsOfService } from 'src/pages/GDPR/TermsOfService'
import { LoginSignup } from 'src/pages/LoginSignup'
import { OnboardingPage } from 'src/pages/Onboarding'
import PublicBoardPage from 'src/pages/PublicBoard'
import { SettingsPage } from 'src/pages/Settings'
import { AppLayout } from 'src/shared/components/AppLayout'

import { ProtectedRoute } from './components/ProtectedRoute'
import { paths } from './paths'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout sidebar />,
    children: [
      {
        index: true,
        element: <ProtectedRoute component={BoardsPage} />,
      },
      {
        path: paths.boards,
        element: <ProtectedRoute component={BoardsPage} />,
      },
      {
        path: paths.onboarding,
        element: <ProtectedRoute component={OnboardingPage} />,
      },
      {
        path: `${paths.boards}/:board_id`,
        element: <ProtectedRoute component={BoardPage} />,
      },
      {
        path: paths.settingsPage,
        element: <ProtectedRoute component={SettingsPage} />,
      },
    ],
  },
  // --- Routes without sidebar ---
  {
    path: '/',
    element: <AppLayout />,
    children: [
      // --- Protected routes ---
      {
        path: paths.loginSignUp + '/:org_id?',
        element: <LoginSignup />,
      },
      // Open routes
      {
        path: `${paths.publicBoard}/:board_id`,
        element: <PublicBoardPage />,
      },
      {
        path: paths.cookieConsent,
        element: <CookieConsent />,
      },
      {
        path: paths.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: paths.termsOfService,
        element: <TermsOfService />,
      },
    ],
  },
])

// {
//   path: paths.candidates + '/:company_id/:profile_ids',
//   element: <SharedProfiles />,
// },
